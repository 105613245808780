import React, { useEffect, useState } from "react";
import { Route } from "react-router-dom";
import gsap from "gsap";
//style
import "./styles/App.scss";
//components
import Header from "./components/header";
import Navigation from "./components/navigation";
//pages
import Home from "./pages/home";
import Contact from "./pages/contact";
import Gallery from "./pages/gallery";
import Products from "./pages/products";
import Story from "./pages/story";
//routes
const routes = [
  { path: "/", name: "home", Component: Home },
  { path: "/contact", name: "contact", Component: Contact },
  { path: "/gallery", name: "gallery", Component: Gallery },
  { path: "/products", name: "products", Component: Products },
  { path: "/story", name: "about", Component: Story },
];

function debounce(fn, ms) {
  let timer;
  return () => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      timer = null;
      fn.apply(this, arguments);
    }, ms);
  };
}

function App() {
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });

  useEffect(() => {
    //Grab inner height of window for mobile reasons when dealing with vh
    let vh = dimensions.height * 0.01;
    //Set css varible vh
    document.documentElement.style.setProperty("--vh", `${vh}px`);

    // Preventing flash from happening.
    gsap.to("body", 0, { css: { visibility: "visible" } });

    // Browser size calculate
    const debouncedHandleResize = debounce(function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    }, 1000);

    window.addEventListener("resize", debouncedHandleResize);
    return () => {
      window.removeEventListener("resize", debouncedHandleResize);
    };
  });

  return (
    <>
      <Header dimensions={dimensions} />
      <div className="App">
        {routes.map(({ path, Component }) => (
          <Route key={path} exact path={path}>
            <Component />
          </Route>
        ))}
      </div>
      <Navigation />
    </>
  );
}

export default App;
