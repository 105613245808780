import React, { useRef, useEffect } from "react";
import { gsap } from "gsap";
import { TimelineLite, Power2 } from "gsap";
import { Player, ControlBar } from "video-react";
import CSSRulePlugin from "gsap/CSSRulePlugin";
gsap.registerPlugin(CSSRulePlugin);

const Home = () => {
  let container = useRef(null);
  let videoReveal = CSSRulePlugin.getRule(".video-container:after");

  const tl = new TimelineLite();

  useEffect(() => {
    tl.to(container, 0.2, { css: { visibility: "visible" } }).to(
      videoReveal,
      1.4,
      { width: "0%", ease: Power2.easeInOut }
    );
  });

  document.body.classList.add("white");
  document.body.classList.remove("dark");

  return (
    <div className="main">
      <div ref={(el) => (container = el)} className="container">
        <div className="video-container">
          <div className="video-overlay-click"></div>
          <Player
            autoPlay={true}
            loop={true}
            muted={true}
            src="https://raika.rs/home-video.mp4"
          >
            <ControlBar autoHide={true} className="video-bar" />
          </Player>
        </div>
      </div>
    </div>
  );
};

export default Home;
