import React from "react";
import gsap from "gsap";
import { Power4 } from "gsap";
import Swiper from "swiper";
import "swiper/css/swiper.css";
import { ReactComponent as Model1 } from "../assets/model-01.svg";
import { ReactComponent as Model2 } from "../assets/model-02.svg";
import { ReactComponent as Model3 } from "../assets/model-03.svg";

class Products extends React.Component {
  componentDidMount() {
    this.swiper = new Swiper(".swiper-container", {
      slidesPerView: 1,
      spaceBetween: 0,
      centeredSlides: true,
      mousewheel: true,
      keyboard: true,
      loop: true,
      speed: 300,
      slideToClickedSlide: true,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      // Responsive breakpoints
      breakpoints: {
        980: {
          slidesPerView: 3,
        },
      },
    });

    gsap.to(".swiper-container", 0.9, {
      opacity: 1,
      delay: 0.6,
      transform: "translateX(0)",
    });
    gsap.to(".swiper-slide", 0, {
      scale: 0.7,
    });
    gsap.to(".swiper-slide-active", 0, {
      scale: 1.3,
    });
    this.swiper.on("slideChange", function () {
      gsap.to(".swiper-slide-active", 0.6, {
        scale: 0.7,
      });
    });
    this.swiper.on("slideChangeTransitionEnd", function () {
      gsap.to(".swiper-slide-active", 0.6, {
        scale: 1.3,
        ease: Power4.easeInOut,
      });
    });

    document.body.classList.add("dark");
    document.body.classList.remove("white");
  }

  render() {
    return (
      <div className="main">
        <h2>Hover me!</h2>
        <div className="swiper-container">
          <div className="swiper-wrapper">
            <div className="swiper-slide">
              <Model1 />
            </div>
            <div className="swiper-slide">
              <Model2 />
            </div>
            <div className="swiper-slide">
              <Model3 />
            </div>
          </div>
          <div className="swiper-navigation-holder">
            <div className="swiper-button-prev"></div>
            <div className="swiper-button-next"></div>
          </div>
        </div>
      </div>
    );
  }
}

export default Products;
