import React from "react";
import gsap from "gsap";
import { Power4 } from "gsap";
import Swiper from "swiper";
import "swiper/css/swiper.css";

//Data
import { galleryPhotos } from "../assets/photos";

class Gallery extends React.Component {
  componentDidMount() {
    this.swiper = new Swiper(".swiper-container", {
      slidesPerView: 1,
      spaceBetween: 60,
      centeredSlides: true,
      mousewheel: true,
      keyboard: true,
      loop: true,
      speed: 300,
      slideToClickedSlide: true,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      //autoplay: {
      //  delay: 5000,
      //},
      // Responsive breakpoints
      breakpoints: {
        980: {
          slidesPerView: 3,
        },
      },
      //direction: "vertical",
    });

    gsap.to(".swiper-container", 0.9, {
      opacity: 1,
      delay: 0.6,
      transform: "translateX(0)",
    });
    gsap.to(".swiper-slide", 0, {
      scale: 0.8,
    });
    gsap.to(".swiper-slide-active", 0, {
      scale: 1,
    });
    gsap.to(".slide-number span", 0, {
      x: -100,
    });
    gsap.to(".swiper-slide-active .slide-number span", 0, {
      x: 0,
    });
    this.swiper.on("slideChange", function () {
      gsap.to(".swiper-slide-active", 0.6, {
        scale: 0.8,
      });
      gsap.to(".slide-number span", 0.6, {
        x: -100,
      });
    });
    this.swiper.on("slideChangeTransitionEnd", function () {
      gsap.to(".swiper-slide-active", 0.6, {
        scale: 1,
        ease: Power4.easeInOut,
      });
      gsap.to(".swiper-slide-active .slide-number span", 0.6, {
        x: 0,
      });
    });
    document.body.classList.add("dark");
    document.body.classList.remove("white");
  }

  render() {
    return (
      <div>
        <div className="main">
          <div className="swiper-container">
            <div className="swiper-wrapper">
              {galleryPhotos.map((photo, index) => (
                <div key={index} className="swiper-slide">
                  <div className="slide-number">
                    <p>
                      <span>{photo.id}</span>
                    </p>
                  </div>
                  <div
                    className="slide-img"
                    style={{ backgroundImage: `url(${photo.image})` }}
                  ></div>
                </div>
              ))}
            </div>
            <div className="swiper-navigation-holder">
              <div className="swiper-button-prev"></div>
              <div className="swiper-button-next"></div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Gallery;
