export const galleryPhotos = [
  {
    id: "001",
    image: "https://raika.rs/imgs/1.jpg",
  },
  {
    id: "002",
    image: "https://raika.rs/imgs/2.jpg",
  },
  {
    id: "003",
    image: "https://raika.rs/imgs/3.jpg",
  },
  {
    id: "004",
    image: "https://raika.rs/imgs/4.jpg",
  },
  {
    id: "005",
    image: "https://raika.rs/imgs/5.jpg",
  },
  {
    id: "006",
    image: "https://raika.rs/imgs/6.jpg",
  },
  {
    id: "007",
    image: "https://raika.rs/imgs/7.jpg",
  },
  {
    id: "008",
    image: "https://raika.rs/imgs/8.jpg",
  },
  {
    id: "009",
    image: "https://raika.rs/imgs/9.jpg",
  },
  {
    id: "010",
    image: "https://raika.rs/imgs/10.jpg",
  },
  {
    id: "011",
    image: "https://raika.rs/imgs/11.jpg",
  },
  {
    id: "012",
    image: "https://raika.rs/imgs/12.jpg",
  },
  {
    id: "013",
    image: "https://raika.rs/imgs/13.jpg",
  },
  {
    id: "014",
    image: "https://raika.rs/imgs/14.jpg",
  },
  {
    id: "015",
    image: "https://raika.rs/imgs/15.jpg",
  },
  {
    id: "016",
    image: "https://raika.rs/imgs/16.jpg",
  },
  {
    id: "017",
    image: "https://raika.rs/imgs/17.jpg",
  },
  {
    id: "018",
    image: "https://raika.rs/imgs/18.jpg",
  },
  {
    id: "019",
    image: "https://raika.rs/imgs/19.jpg",
  },
  {
    id: "020",
    image: "https://raika.rs/imgs/20.jpg",
  },
  {
    id: "021",
    image: "https://raika.rs/imgs/21.jpg",
  },
  {
    id: "022",
    image: "https://raika.rs/imgs/22.jpg",
  },
  {
    id: "023",
    image: "https://raika.rs/imgs/23.jpg",
  },
  {
    id: "024",
    image: "https://raika.rs/imgs/24.jpg",
  },
  {
    id: "025",
    image: "https://raika.rs/imgs/25.jpg",
  },
  {
    id: "026",
    image: "https://raika.rs/imgs/26.jpg",
  },
  {
    id: "027",
    image: "https://raika.rs/imgs/27.jpg",
  },
  {
    id: "028",
    image: "https://raika.rs/imgs/28.jpg",
  },
  {
    id: "029",
    image: "https://raika.rs/imgs/29.jpg",
  },
  {
    id: "030",
    image: "https://raika.rs/imgs/30.jpg",
  },
  {
    id: "031",
    image: "https://raika.rs/imgs/31.jpg",
  },
  {
    id: "032",
    image: "https://raika.rs/imgs/32.jpg",
  },
  {
    id: "033",
    image: "https://raika.rs/imgs/33.jpg",
  },
  {
    id: "034",
    image: "https://raika.rs/imgs/34.jpg",
  },
  {
    id: "035",
    image: "https://raika.rs/imgs/35.jpg",
  },
  {
    id: "036",
    image: "https://raika.rs/imgs/36.jpg",
  },
];
