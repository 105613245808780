import React, { useRef, useEffect } from "react";
import { TimelineLite } from "gsap";
import { Player, ControlBar, BigPlayButton } from "video-react";
import "video-react/styles/scss/video-react.scss";

const Story = () => {
  let container = useRef(null);
  const tl = new TimelineLite();

  useEffect(() => {
    tl.to(container, 0.2, { css: { visibility: "visible" } });
  });

  document.body.classList.add("white");
  document.body.classList.remove("dark");

  return (
    <div className="main">
      <div ref={(el) => (container = el)} className="container">
        <div className="video-container">
          <div className="video-overlay-click"></div>
          <Player
            autoPlay={true}
            loop={true}
            muted={true}
            src="https://raika.rs/raika.mp4"
          >
            <BigPlayButton position="center" />
            <ControlBar autoHide={true} className="video-bar" />
          </Player>
        </div>
      </div>
    </div>
  );
};

export default Story;
