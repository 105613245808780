import React, { useRef, useEffect } from "react";
import gsap, { TimelineLite, Power4 } from "gsap";
import { ReactComponent as FacebookIcon } from "../assets/facebook-icon.svg";
import { ReactComponent as InstagramIcon } from "../assets/instagram-icon.svg";

const Contact = () => {
  let container = useRef(null);
  const tl = new TimelineLite();

  useEffect(() => {
    tl.to(container, 500, { css: { visibility: "visible" } });
    gsap.to(".container-in", 0.9, {
      opacity: 1,
      delay: 0.7,
      ease: Power4.easeOut,
    });
  });

  document.body.classList.add("dark");
  document.body.classList.remove("white");

  return (
    <div className="main">
      <div ref={(el) => (container = el)} className="container">
        <div className="container-in">
          <h1>Contact us</h1>
          <p>hello@raika.rs</p>
          <ul>
            <li>
              <a
                href="https://www.instagram.com/raika.rs/"
                rel="noopener noreferrer"
                target="_blank"
              >
                <InstagramIcon />
                <span>Instagram</span>
              </a>
            </li>
            <li>
              <a
                href="https://www.facebook.com/raika.ofc/"
                rel="noopener noreferrer"
                target="_blank"
              >
                <FacebookIcon />
                <span>Facebook</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Contact;
