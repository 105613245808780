import React, { useRef } from "react";
import { NavLink } from "react-router-dom";
import gsap from "gsap";

import ModelsImg from "../assets/menu-img-01.jpg";
import VideoImg from "../assets/menu-img-02.jpg";
import GalleryImg from "../assets/menu-img-03.jpg";
import ContactImg from "../assets/menu-img-04.jpg";


const backgrounds = [
  { name: "products", image: ModelsImg },
  { name: "story", image: VideoImg },
  { name: "gallery", image: GalleryImg },
  { name: "contact", image: ContactImg },
];

const Navigation = () => {
  let menuBackground = useRef(null);

  // adds menu image once you hover on
  const handleBackground = (backgrounds, target) => {
    gsap.to(target, {
      duration: 0,
      "background-image": `url(${backgrounds})`,
    });
    gsap.to(target, {
      duration: 0.4,
      opacity: 0.1,
      ease: "power3.inOut",
    });
    gsap.from(target, {
      duration: 0.4,
      skewY: 1,
      transformOrigin: "right top",
    });
  };

  // Removes the menu image once you hover off
  const hanldeBackgroundReturn = (target) => {
    gsap.to(target, {
      duration: 0,
      skewY: 0,
    });
    gsap.to(target, {
      duration: 0.4,
      opacity: 0,
      skewY: 0,
    });
  };

  return (
    <nav>
      <div className="nav-container">
        <ul className="nav-links">
        <li><a href="https://raika.rs/catalogue-raika.pdf" target="_blank">Catalogue</a></li>

          {backgrounds.map((el) => (
            <li key={el.name}>
              <NavLink
                to={el.name}
                exact
                onMouseEnter={() => handleBackground(el.image, menuBackground)}
                onMouseOut={() => hanldeBackgroundReturn(menuBackground)}
              >
                {el.name}
              </NavLink>
            </li>
          ))}
        </ul>
        <div
          ref={(el) => (menuBackground = el)}
          className="menu-backgrounds"
        ></div>
      </div>
    </nav>
  );
};

export default Navigation;
